var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "reportContainer reportContainer--veldwerk",
          attrs: { "grid-list-lg": "" }
        },
        [
          _c(
            "v-layout",
            { attrs: { "pb-5": "", row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.reportModel
                    ? _c(
                        "div",
                        { staticClass: "elementPanel profile" },
                        [
                          _vm.isLoading
                            ? _c("LoaderCard", {
                                attrs: {
                                  flat: "",
                                  type: "spinner--center",
                                  minHeight: "300px"
                                }
                              })
                            : _vm._e(),
                          !_vm.isLoading
                            ? [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "profileContainer",
                                    attrs: { row: "", wrap: "" }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      [
                                        [
                                          _c(
                                            "h1",
                                            {
                                              staticClass: "profile__userName"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportModel.case_number
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "profile__userRoles"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.reportModel.type
                                                      ? _vm.reportModel.type
                                                          .name
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Naam aanvrager")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.reportModel.applicant
                                                ? _vm.reportModel.applicant.name
                                                : ""
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Adres")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _vm.reportModel.address
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: _vm.reportModel.googleMapUrl(),
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.reportModel.address
                                                        .street
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.reportModel.address
                                                        .number
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.reportModel.address
                                                        .number_add
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.reportModel.address
                                                        .postcode
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.reportModel.address
                                                        .city
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Opgestuurd")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatDateSeconds")(
                                                  _vm.reportModel
                                                    .submitted_at ===
                                                    "0000-00-00 00:00:00"
                                                    ? "-"
                                                    : _vm.reportModel
                                                        .submitted_at
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Rapport type")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.reportModel.type
                                                ? _vm.reportModel.type.name
                                                : ""
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _vm.reportModel.isAannemersReport
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass: "infoPanelColumn",
                                            attrs: { sm4: "" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "infoPanelColumn__fadedText"
                                              },
                                              [_vm._v("Naam Aannemer")]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.reportModel.isAannemersReport
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass: "infoPanelColumn",
                                            attrs: { sm4: "" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "infoPanelColumn__fadedText"
                                              },
                                              [_vm._v("Naam Expert")]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _vm.reportModel.expert
                                          ? _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportModel.expert.name
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.reportModel.isAannemersReport
                                  ? _c(
                                      "v-layout",
                                      { attrs: { row: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "infoPanelColumn",
                                            attrs: { sm4: "" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "infoPanelColumn__fadedText"
                                              },
                                              [_vm._v("Naam Expert")]
                                            )
                                          ]
                                        ),
                                        _vm.reportModel.second_expert
                                          ? _c(
                                              "v-flex",
                                              {
                                                staticClass: "infoPanelColumn",
                                                attrs: { sm8: "" }
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.reportModel
                                                        .second_expert.name
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("profile.reportTag")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      _vm._l(_vm.reportModel.tags, function(
                                        tag,
                                        index
                                      ) {
                                        return _c("p", { key: index }, [
                                          _vm.reportModel.tags.length > 1
                                            ? _c("span", [
                                                tag[0]
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(tag.name) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        "," + _vm._s(tag.name)
                                                      )
                                                    ])
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " " + _vm._s(tag.name) + " "
                                                )
                                              ])
                                        ])
                                      }),
                                      0
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Bouwjaar")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.generalKeys.BouwJaar
                                                ? _vm.generalKeys.BouwJaar.value
                                                : "-"
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Eigendom sinds")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.generalKeys.EigendomSinds
                                                ? _vm.generalKeys.EigendomSinds
                                                    .value
                                                : "-"
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Verhaal aanvrager")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.generalKeys.storyOfApplicant
                                                ? _vm.generalKeys
                                                    .storyOfApplicant.value
                                                : "-"
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Opmerking deskundige")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeString(
                                                _vm.generalKeys.ExpertComment
                                                  ? _vm.generalKeys
                                                      .ExpertComment.value
                                                  : "-"
                                              )
                                            )
                                          }
                                        }),
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeString(
                                                _vm.generalKeys
                                                  .OpmerkingDeskundige
                                                  ? _vm.generalKeys
                                                      .OpmerkingDeskundige.value
                                                  : "-"
                                              )
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Opmerking aanvrager")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeString(
                                                _vm.generalKeys
                                                  .relevantRemarksApplicant
                                                  ? _vm.generalKeys
                                                      .relevantRemarksApplicant
                                                      .value
                                                  : "-"
                                              )
                                            )
                                          }
                                        }),
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeString(
                                                _vm.generalKeys
                                                  .OpmerkingAanvrager
                                                  ? _vm.generalKeys
                                                      .OpmerkingAanvrager.value
                                                  : "-"
                                              )
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm4: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "infoPanelColumn__fadedText"
                                          },
                                          [_vm._v("Geaccepteerd")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "infoPanelColumn",
                                        attrs: { sm8: "" }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatDateSeconds")(
                                                  _vm.reportModel
                                                    .approved_at ===
                                                    "0000-00-00 00:00:00"
                                                    ? "-"
                                                    : _vm.reportModel
                                                        .approved_at
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.damages
                    ? [
                        _c("DamagePanel", {
                          ref: "damagePanel",
                          attrs: {
                            damages: _vm.damages,
                            includes: ["media", "repairs"],
                            visibility: _vm.damageVisibility,
                            panelsFromStorage: _vm.panelsFromStorage
                          },
                          on: {
                            reloadDamages: _vm.initialize,
                            panelChanged: function($event) {
                              return _vm.handlePanelChanged($event)
                            }
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ApprovalBar", {
        attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "actionContainer" },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: {
                          color: "success",
                          small: "true",
                          icon: "check_circle"
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.toggleApproveDialog(
                              "in_progress",
                              "Verplaats het rapport naar lopend",
                              "approved"
                            )
                          }
                        }
                      },
                      [_vm._v(" Zet op lopend ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "actionContainer" },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: {
                          color: "success",
                          small: "true",
                          icon: "check_circle"
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.toggleApproveDialog(
                              "submitted",
                              "Verander naar voorcontrole",
                              "approved"
                            )
                          }
                        }
                      },
                      [_vm._v(" Versturen ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.approvalDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.approvalDialogOpen,
                callback: function($$v) {
                  _vm.approvalDialogOpen = $$v
                },
                expression: "approvalDialogOpen"
              }
            },
            [
              _c("ApprovalDialog", {
                attrs: {
                  report: _vm.reportModel,
                  title: _vm.title,
                  status: _vm.status,
                  role: "preCheckImages",
                  showExpertSelect: _vm.status === "in_progress" ? true : false,
                  approval: _vm.approval
                },
                model: {
                  value: _vm.approvalDialogOpen,
                  callback: function($$v) {
                    _vm.approvalDialogOpen = $$v
                  },
                  expression: "approvalDialogOpen"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }