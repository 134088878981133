import { Damage } from '@/models/DamageNew';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { formatDate, sanitizeString } from '@/support/String';
import { parseWarning } from '@/support/Warning';
import { Report as ReportModel, Answer } from '@/models/Report';
import { DamageVisibility } from '@/components/damage-panel/DamagePanel';

import Storage from '@/support/Storage';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';

@Component<PreCheckImages>({
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
    formatDateSeconds: (date: string) => formatDate(date, 'dd-LL-yyyy HH:mm'),
  },
})
export default class PreCheckImages extends Vue {
  public $pageTitle = 'Voorcontrole WoCo';

  protected reportModel: ReportModel = new ReportModel();

  protected damages: Damage[] = [];

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  // approval bar
  protected approvalDialogOpen = false;

  protected approval = '';

  protected status = '';

  protected title = '';

  protected fileUrl ='';

  protected isLoading = false;

  protected damageVisibility: DamageVisibility = {
    media: true,
    editMedia: true,
    rejectImage: true,
    status: true,
  };

  protected generalKeys: GeneralKey = {
    SchadeadresStraat: GeneralKeys.SchadeadresStraat,
    SchadeadresHuisnummer: GeneralKeys.SchadeadresHuisnummer,
    SchadeadresHuisnummerToevoeging: GeneralKeys.SchadeadresHuisnummerToevoeging,
    SchadeadresPlaats: GeneralKeys.SchadeadresPlaats,
    SchadeadresPostcode: GeneralKeys.SchadeadresPostcode,
    DatumAanvraag: GeneralKeys.DatumAanvraag,
    BouwJaar: GeneralKeys.BouwJaar,
    EigendomSinds: GeneralKeys.EigendomSinds,
    storyOfApplicant: GeneralKeys.storyOfApplicant,
    ExpertComment: GeneralKeys.ExpertComment,
    relevantRemarksApplicant: GeneralKeys.relevantRemarksApplicant,
  };

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getReport();
    await this.getDamages();
    this.isLoading = false;
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected handlePanelChanged(panels: boolean[]) {
    Storage.set('preCheckImages_panels', JSON.stringify(panels));
  }

  protected get panelsFromStorage() {
    return JSON.parse(`${Storage.get('preCheckImages_panels')}`);
  }

  protected destroyed() {
    Storage.delete('preCheckImages_panels');
  }

  protected async getReport() {
    await new ReportModel()
      .include(['applicant', 'answers', 'validations'])
      .find(this.$route.params.id)
      .then((reportModel: ReportModel) => {
        this.reportModel = reportModel;
        if (! this.fileUrl) {
          this.fileUrl = reportModel.links.quick;
        }
        this.initBreadcrumb();
        this.setGeneralInfo();
        this.isLoading = false;
        this.isAllowedToViewDialog = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getDamages() {
    this.damages = await new Damage()
      .refactor()
      .filter({
        report: this.$route.params.id,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Voorcontrole' },
        ],
      });
  }

  protected toggleApproveDialog(status: string, title: string, approval: string) {
    this.status = status;
    this.approval = approval;
    this.title = title;
    this.approvalDialogOpen = true;
  }

  protected parseWarning(alert: string) {
    return parseWarning(alert);
  }

  protected setGeneralInfo() {
    const keys = Object.keys(this.generalKeys);
    if (! this.reportModel.answers) {
      return;
    }

    this.reportModel.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.initialize();
  }
}
